.arg-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
}

.arg-skeleton {
  position: absolute;
  background-color: #E0E6EF;
  border-radius: 8px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg,
    rgba(#fff, 0) 0,
    rgba(#fff, 0.2) 20%,
    rgba(#fff, 0.5) 60%,
    rgba(#fff, 0));
    animation: arg-skeleton-shimmer 2s infinite;
    content: '';
  }

  @keyframes arg-skeleton-shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}