@import './fonts/icomoon/style.css';

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}


@font-face {
  font-family: 'Awesome4';
  src: url('./fonts/awesome4/fontawesome-webfont.eot?v=4.7.0');
  src: url('./fonts/awesome4/fontawesome-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: var(--arg-font-family);
  font-size: var(--arg-font-size);
}

button {
  font-family: var(--arg-font-family);
}
